<template>
  <template v-if="enabled">
    <slot />
  </template>
</template>

<script lang="ts" setup>
import type { FeatureFlagKey } from "../../model/feature-flag.model";

const properties = defineProps<{
  flag: FeatureFlagKey;
}>();

const enabled = useFeatureFlag(properties.flag);
</script>
